html,
body {
  min-height: 100vh;
  margin: 0;
  min-height: 100vh;
}
#main{
  min-height: 100vh;
  margin: 0;
  min-height: 100vh;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  transition: 0.25s; 
}
#Content {
  margin-top: 10%;
  width: 80%;
  padding: 40px;
  text-align: center;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  
}
#tittle {
  color: white;
  font-size: 4em;
  margin-left: 1em;
  font-weight: bold;
}
#luz {
  padding-top: 1em;
}
#col {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}
#resp {
  color: white;
  margin-top: 5em;
  text-align: center;
}
#result {
  font-size: 2em;
  font-weight: bold;
  color: chocolate;
  padding: 1em;
  background-color: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
#notification{
  flex-shrink: 0;
  transition: 0.25s; 
  margin-left: 5em;
  top: 5em;
  width: 30em;
}

@media screen and (max-width:480px) {
  #tittle {
    font-size: 2em;
  }
  #notification{
    margin-left: 3em;
    top: 1em;
    width: 25em;
  }
   
}

.loading-overlay {
  position: fixed; 
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); 
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
